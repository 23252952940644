import { CatPhoto } from "./HomePage";

export default function CatPhotoCard(props: { photo: CatPhoto }): JSX.Element {

    return (
        <div className="nursery_pet_short_card">
            <h3>{props.photo.date}</h3>
            <h4>{props.photo.title}</h4>
             
            <img alt={'фото'} src={props.photo.path_file} 
                    className="card_item_image"
            />
            <p>{props.photo.description}</p>
        </div>
    );
}
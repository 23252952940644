import { useState } from "react";
import { NurseryCard } from "./NurseryPage";
import ObjectList from "./ObjectList";

interface CatNurseryCardProps {
    nurseryCard: NurseryCard;
}

export default function CatNurseryFullCard(props: CatNurseryCardProps): JSX.Element {
    const [activeNurserySection, setActiveNurserySection] = useState<number>(1);

    const handlerClick = (id: number, event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation(); // Останавливаем всплытие
        setActiveNurserySection(id);
    };
    
    const nurserySectionArray = [
        {
            id: 1,
            name: "Производители",
            isEmpty: false,
            url: `https://mitrapro.tech/cat_nurseries/${props.nurseryCard.id}/BREEDING/cats`,
            dataType: "CatCard",
        },
        {
            id: 2,
            name: "Котята",
            isEmpty: false,
            url: `https://mitrapro.tech/cat_nurseries/${props.nurseryCard.id}/KITTEN/cats`,
            dataType: "CatCard",
        },
        {
            id: 3,
            name: "Ньютеры",
            isEmpty: false,
            url: `https://mitrapro.tech/cat_nurseries/${props.nurseryCard.id}/NEUTER/cats`,
            dataType: "CatCard",
        },
        {
            id: 4,
            name: "Ветераны",
            isEmpty: false,
            url: `https://mitrapro.tech/cat_nurseries/${props.nurseryCard.id}/VETERAN/cats`,
            dataType: "CatCard",
        },
        {
            id: 5,
            name: "Выпускники",
            isEmpty: false,
            url: `https://mitrapro.tech/cat_nurseries/${props.nurseryCard.id}/GRADUATE/cats`,
            dataType: "CatCard",
        },
        {
            id: 6,
            name: "Достижения",
            isEmpty: false,
            url: `https://mitrapro.tech/cat_nurseries/${props.nurseryCard.id}/achievement-photos/`,
            dataType: "CatPhoto",
        },
        {
            id: 7,
            name: "Фото",
            isEmpty: false,
            url: `https://mitrapro.tech/cat_nurseries/${props.nurseryCard.id}/photos/`,
            dataType: "CatPhoto",
        }, 
    ]

    const nurserySectionJSX = nurserySectionArray.map((item) =>
        (!item.isEmpty) &&
            <>
                <div className="nursery_section" key={item.id}  onClick= {(event) => handlerClick((activeNurserySection === item.id) ? 0 : item.id, event)}>
                    <div className={(activeNurserySection === item.id) ? "nursery_section_head_active" : "nursery_section_head"}>
                        <h2>{item.name}</h2>
                    </div>
                    <div className={(activeNurserySection === item.id) ? "nursery_section_button_active" : "nursery_section_button"}>
                        <h2>{(activeNurserySection === item.id) ?  "\xD7" : "\x2B"}</h2>
                    </div>
                </div>
                {(activeNurserySection === item.id) ? 
                    <div className="nursery_section_card">
                        <ObjectList url={item.url} dataType={item.dataType} />
                    </div> : <></>
                }
            </>
    );

    const nurseryInformJSX: JSX.Element = 
        <>
            <div className="nursery_info">
                <table className="styled-table">
                    <tbody>
                        <tr className="row">
                            <td className="cell1">{'Заводчик: '}</td>
                            <td className="cell2">{props.nurseryCard.breeder_name}</td>
                        </tr>
                        <tr className="row">
                            <td className="cell1">{'Клуб: '}</td>
                            <td className="cell2">{props.nurseryCard.club}</td>
                        </tr>
                        <tr className="row">
                            <td className="cell1">{'Фелинологическая система: '}</td>
                            <td className="cell2">{props.nurseryCard.federation}</td>
                        </tr>
                        <tr className="row">
                            <td className="cell1">{'Город: '}</td>
                            <td className="cell2">{props.nurseryCard.location}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </>

    const nurseryFullCardJSX: JSX.Element = 
        <>
            <div className="nursery_box">
                <h1>Питомник {props.nurseryCard.name}</h1>
                <p>{props.nurseryCard.description}</p>
                {nurseryInformJSX}
                {nurserySectionJSX}
            </div>
        </>
    
    return (
        <>
            {nurseryFullCardJSX}
        </>
    );
}
import { useState } from "react";
import { useSelector } from "react-redux";

interface TelNumProps {
    telNum: string;
    telNumHiden: string;
    className: string;
}
export default function TelephoneNumberHiden(props:TelNumProps): JSX.Element {
    const { userIsAuthenticated } = useSelector((state:any) => state.user);
    
    const handleChildClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        setShowTelNumber(!showTelNumber);
    };

    const [showTelNumber, setShowTelNumber] = useState(false);
    
    const telephoneNumber: string = props.telNum ? props.telNum.trim() : '';
    const telNumHiden: string = (telephoneNumber === "") ? "" : props.telNumHiden

    return (
        <>
            <div className={props.className} onClick={handleChildClick}>
                {(showTelNumber && userIsAuthenticated) ? telephoneNumber : telNumHiden}
            </div>
        </>
    );
}
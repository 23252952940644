import PetShortCard from "./PetShortCard";
import Advertiser from "./Advertiser";
import { useEffect, useState } from "react";
import PetFullCardPage from "./PetFullCardText";
import { useSelector } from "react-redux";
/*import { cardList } from "./DebugData/DebugCardList";*/
import { advertiserList } from "./DebugData/DebugAdvertiserList";
import TelephoneNumberHiden from "./TelephoneNumberHiden";
import PetFullCardText from "./PetFullCardText";
import ObjectList from "./ObjectList";

export type Photo = {
    id: number,
    src: string,
    alt: string,
}

export type CatPhoto = {
    id: number,
    cat: number,
    path_file: string,
    date: string | null,
    title: string,
    description: string,
    is_show_achivement: boolean,
    is_show_photo: boolean,
}

export interface Card {
    sex: 'MALE' | 'FEMALE';
    id: number;
    name: string;
    breed: string;
    dob: string; 
    price: string;
    photos: Array<Photo>;
    description: string;
    telNumber: string;
}

export type CatCard = {
    id: number;
    user: number;
    breed: string;
    name: string;
    sir_id: number;
    sir_name: string;
    sir_coat: string;
    dam_id: number;
    dam_name: string;
    dam_coat: string;
    birthday: null | string,
    pet_price: string;
    breed_price: string;
    coat: string;
    sex: 'MALE' | 'FEMALE';
    sale_status:  'SALE' | 'SOLD' | 'RESERVED' | 'NOT_SALE'
    is_neuter: boolean;
    adv_text: string;
    tel_number: string;
    photos: Array<CatPhoto>;
    }

export interface Advert {
    id: number;
    name: string;
    url: string;
}

interface ItemShowObject {
        kind: string;
        id: number;
    }

export default function HomePage(): JSX.Element {
    const {sectionID} = useSelector((state: any) => state.section);
    const { userIsAuthenticated } = useSelector((state:any) => state.user);

    const urlAdvertiser: string = 'https://mitrapro.tech/cats_withphoto?is_show_advertiser=true';
    const dataType: string = "CatCard";

    return (
        <>
            {sectionID===1 && <ObjectList url={urlAdvertiser} dataType={dataType} />}    
        </>
    );
}


import { useEffect, useState } from "react";
import { CatCard, CatPhoto } from "./HomePage";
import PetShortCard from "./PetShortCard";
import { NurseryCard } from "./NurseryPage";
import CatPhotoCard from "./CatPhotoCard";
import CatNurseryShortCard from "./CatNurseryShortCard";
import CatNurseryFullCard from "./CatNurseryFullCard";
import TelephoneNumberHiden from "./TelephoneNumberHiden";
import PetFullCardText from "./PetFullCardText";
import CatFullCard from "./CatFullCard";

//interface ObjectProps<T> {
//    url: string;
//    mapData: (data: any) => T[]; // Функция для преобразования данных в нужный формат
//}

interface ObjectProps {
    url: string;
    dataType: string;
}

export default function ObjectList(props: ObjectProps): JSX.Element {
    const paginationServer: number = 20;
    const [paginationLastPage, setPaginationLastPage] = useState<number>(0);
    const [paginationActivePage, setPaginationActivePage] = useState<number>(1);
    const [urlPreviousPage, setURLPreviousPage] = useState<string | null>(null);
    const [urlNextPage, setURLNextPage] = useState<string | null>(null);

    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const [objectArray, setObjectArray] = useState<CatCard[] | CatPhoto[] | NurseryCard[]>([]);

    //const [showObjectWindow, setShowObjectWindow] = useState<CatCard | CatPhoto | NurseryCard | null>(null);
    const [showObjectWindow, setShowObjectWindow] = useState<number>(-1);

    function getPaginationItem(activePage: number, lastPage: number, index: number = 0): number {
        var paginationArr: (number)[] = [0, 0, 0, 0, 0];

        if (lastPage === 2) {
            paginationArr = [-1, -1, -1, -1, -1];
        }

        if (lastPage === 3) {
            paginationArr = [-1, -1, 2, -1, -1];
        }

        if (lastPage === 4) {
            paginationArr = [-1, 2, 3, -1, -1];
        }

        if (lastPage === 5) {
            paginationArr = [-1, 2, 3, 4, -1];
        }

        if (lastPage === 6) {
            paginationArr = [2, 3, 4, -1, 5];
        }

        if (lastPage === 7) {
            paginationArr = [2, 3, 4, 5, 6];
        }

        if (lastPage > 7) {
            if (activePage <= 4) {
                paginationArr = [2, 3, 4, 5, 6];
            } else {
                if (activePage >= lastPage - 3) {
                    paginationArr = [ lastPage - 5, 
                                        lastPage - 4, 
                                        lastPage - 3, 
                                        lastPage - 2, 
                                        lastPage - 1
                                    ];
                } else {
                    paginationArr = [ activePage - 2, 
                        activePage - 1,
                        activePage,
                        activePage + 1,
                        activePage + 2
                    ];
                }
            }
            
            if (paginationArr[0] !== 2) {
                paginationArr[0] = 0;
            }

            if (paginationArr[4] !== lastPage - 1) {
                paginationArr[4] = 0;
            }
        }

        return paginationArr[index];
    }

    const handlerCloseErrorWindow = () => {
        setError(null);
    }

    function requestObjectList(url: string, isFirstRecord: boolean = true) {
        setLoading(true);   // Устанавливаем состояние загрузки
        setError(null);     // Очищаем предыдущие ошибки
        
        const replaceHttpWithHttps = (url: string | null): string | null => {
            if (url === null) {
                return url;
            } else {
                return url.replace("http://", "https://");
            }
        };

        const isPageInclude = (url: string | null): boolean => {
            if (url === null) return false;
            return url.includes("?page=");
        }
        
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        };
  
        fetch(url, requestOptions)
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`Запрос не прошёл. Сервер не отвечает. Проверьте соединение с Интернет. Статус: ${response.status}`);
                } 
                return response.json();
            })
            .then(data => {
                let forPaginationLastPage = Math.ceil(data["count"] / paginationServer);
                let forURLNextPage = replaceHttpWithHttps(data["next"]);
                let forURLPreviousPage = replaceHttpWithHttps(data["previous"]);
                let forPaginationActivePage = 1;
                let forShowObjectWindow: number = -1;
                //let forActivePhoto = 0;
                
                let activePageSetting: boolean = false;

                if (!activePageSetting && forURLPreviousPage === null) {
                    forPaginationActivePage=1;
                    activePageSetting = true;
                }

                if (!activePageSetting && !isPageInclude(forURLPreviousPage)) {
                    forPaginationActivePage=2;
                    activePageSetting = true;
                }
                
                if (!activePageSetting && forURLNextPage === null) {
                    forPaginationActivePage=forPaginationLastPage;
                    activePageSetting = true;
                } 
                    
                if (!activePageSetting && forURLNextPage !== null) {
                    forPaginationActivePage=parseInt(forURLNextPage.split("?page=")[1], 10) - 1;
                    activePageSetting = true;
                }
                    
                if (!activePageSetting && forURLPreviousPage !== null) {
                    forPaginationActivePage=parseInt(forURLPreviousPage.split("?page=")[1], 10) + 1;
                    activePageSetting = true;
                }
                
                setObjectArray([...data["results"]]);
                const arr = data["results"];
                //const arr = props.mapData(data["results"]); // Преобразование данных через mapData
                //setObjectArray([...arr]);
                
                if (showObjectWindow >= 0 && arr.length > 0) {
                    if (isFirstRecord) {
                        forShowObjectWindow = 0;
                        //if (arr[0].photos.length > 0) {
                        //    forActivePhoto = arr[0].photos[0].id;
                        //} 
                    } else {
                        forShowObjectWindow = arr.length - 1;
                        //if (arr[arr.length - 1].photos.length > 0) {
                        //    forActivePhoto = arr[arr.length - 1].photos[0].id;
                        //}
                    } 
                }

                setPaginationLastPage(forPaginationLastPage);
                setURLNextPage(forURLNextPage);
                setURLPreviousPage(forURLPreviousPage);
                setPaginationActivePage(forPaginationActivePage);
                setShowObjectWindow(forShowObjectWindow);
                //setActivePhoto(forActivePhoto);
            })
            .catch(error => {
                setError(error.message || 'Произошла ошибка');
            })
            .finally(() => {
                setLoading(false); // Завершаем загрузку
            });
    }

    const handlerClick = (index: number, event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation(); // Останавливаем всплытие
        if (props.dataType !== "CatPhoto") {
            setShowObjectWindow(index);
        }
    }

    const handlerPrevRecord = () => {
        if (showObjectWindow > 0) {
            setShowObjectWindow(showObjectWindow - 1);
        } else {
            if (urlPreviousPage !== null) {
                requestObjectList(urlPreviousPage, false);
            }
        }
    }

    const handlerNextRecord = () => {
        if (showObjectWindow < objectArray.length - 1) {
            setShowObjectWindow(showObjectWindow + 1);
        } else {
            if (urlNextPage !== null) {
                requestObjectList(urlNextPage);
            }
        }
    }

    const handlerCloseFullPetCardWindow = () => {
        setShowObjectWindow(-1);
    }

    const handlerPrevPage = () => {
        if (urlPreviousPage !== null) {
            requestObjectList(urlPreviousPage);
        }
    }

    const handlerNextPage = () => {
        if (urlNextPage !== null) {
            requestObjectList(urlNextPage);
        }
    }

    const handlerSetPage = (num:number) => {
        if (num === paginationActivePage || paginationLastPage=== 1) return;

        if (num > 0 && num <= paginationLastPage) {
            let url: string = "";
            let fulfilled: boolean = false;
            if (urlNextPage !== null) {
                url = urlNextPage.replace(/(\?page=)\d+/, `$1${num}`);
                fulfilled = true;
            } else {
                if (urlPreviousPage !== null) {
                    url = urlPreviousPage.replace(/(\?page=)\d+/, `$1${num}`);
                    fulfilled = true;
                }
            }
            requestObjectList(url);
            return;
        }
    }

    const paginationItem1JSX: JSX.Element = 
        <>
            <div className={(getPaginationItem(paginationActivePage, paginationLastPage, 0) === 0) ? "not_page_number" : 
                (paginationActivePage === getPaginationItem(paginationActivePage, paginationLastPage, 0)) ? "page_number_active" : "page_number"}
                onClick={() => handlerSetPage(getPaginationItem(paginationActivePage, paginationLastPage, 0))}>
            
                {(getPaginationItem(paginationActivePage, paginationLastPage, 0) > 0) ? getPaginationItem(paginationActivePage, paginationLastPage, 0) : "..."}
        
            </div>
        </>

    const paginationItem2JSX: JSX.Element = 
        <>
            <div className={(paginationActivePage === getPaginationItem(paginationActivePage, paginationLastPage, 1)) ? "page_number_active" : "page_number"}
                onClick={() => handlerSetPage(getPaginationItem(paginationActivePage, paginationLastPage, 1))}>
            
                {getPaginationItem(paginationActivePage, paginationLastPage, 1)}
            </div>
        </>

    const paginationItem3JSX: JSX.Element = 
        <>
            <div className={(paginationActivePage === getPaginationItem(paginationActivePage, paginationLastPage, 2)) ? "page_number_active" : "page_number"}
                onClick={() => handlerSetPage(getPaginationItem(paginationActivePage, paginationLastPage, 2))}>
            
                {getPaginationItem(paginationActivePage, paginationLastPage, 2)}

            </div>
        </>

    const paginationItem4JSX: JSX.Element = 
        <>
            <div className={(paginationActivePage === getPaginationItem(paginationActivePage, paginationLastPage, 3)) ? "page_number_active" : "page_number"}
                onClick={() => handlerSetPage(getPaginationItem(paginationActivePage, paginationLastPage, 3))}>

                {getPaginationItem(paginationActivePage, paginationLastPage, 3)}

            </div>
        </>

    const paginationItem5JSX: JSX.Element = 
        <>
            <div className={(getPaginationItem(paginationActivePage, paginationLastPage, 4) === 0) ? "not_page_number" :
                (paginationActivePage === getPaginationItem(paginationActivePage, paginationLastPage, 4)) ? "page_number_active" : "page_number"}
                onClick={() => handlerSetPage(getPaginationItem(paginationActivePage, paginationLastPage, 4))}>

                {((getPaginationItem(paginationActivePage, paginationLastPage, 4) > 0)) ? getPaginationItem(paginationActivePage, paginationLastPage, 4) : "..."}
        
            </div>
        </>


    const paginationBlock: JSX.Element = 
        <>
            <div className="pagination_block">
                <div className="pagination_bar">
                    <button className={`paginator_button ${paginationActivePage === 1 ? 'disabled' : ''}`} 
                        disabled={paginationActivePage===1}>
        
                        <img src="button_left.png" alt="Left" 
                            onClick={() => handlerPrevPage()}/>
    
                    </button>
                    <div className={(paginationActivePage === 1) ? "page_number_active" : "page_number"}
                        onClick={() => handlerSetPage(1)}>
                    
                        {"1"}

                    </div>
            
                    {(getPaginationItem(paginationActivePage, paginationLastPage, 0) >= 0) && paginationItem1JSX}
            
                    {(getPaginationItem(paginationActivePage, paginationLastPage, 1) >= 0) && paginationItem2JSX}
            
                    {(getPaginationItem(paginationActivePage, paginationLastPage, 2) >= 0) && paginationItem3JSX}
            
                    {(getPaginationItem(paginationActivePage, paginationLastPage, 3) >= 0) && paginationItem4JSX}
            
                    {(getPaginationItem(paginationActivePage, paginationLastPage, 4) >= 0) && paginationItem5JSX}
            
                    <div className={(paginationActivePage === paginationLastPage) ? "page_number_active" : "page_number"}
                        onClick={() => handlerSetPage(paginationLastPage)}>

                        {paginationLastPage}
                    </div>             
                
                    <button className={`paginator_button ${paginationActivePage === paginationLastPage ? 'disabled' : ''}`} 
                        disabled={paginationActivePage===paginationLastPage}>
        
                        <img src="button_right.png" alt="Right"
                            onClick={() => handlerNextPage()}/>

                    </button>
                </div> 
            </div>
        </>

    const lockPreviosRecordButton: boolean = showObjectWindow === 0 && paginationActivePage === 1;
    const lockNextRecordButton: boolean = showObjectWindow === objectArray.length - 1 && paginationActivePage === paginationLastPage;
    
    const showFullCatCardWindowJSX: JSX.Element = 
        <>
            <div className="full_card_modal_overlay">
                <div className='full_card_modal_content_common'>
                    <div className="full_card_column_button">
                        <div className="full_card_button"></div>
                        <div className="full_card_button">
                            <button 
                                className={`full_card_step_button ${lockPreviosRecordButton ? 'disabled' : ''}`} 
                                disabled={lockPreviosRecordButton}
                                onClick={handlerPrevRecord}>
                            
                                    <img src="button_left.png" alt="Left"/>
                        
                            </button>
                        </div>
                        <div className="full_card_button"></div>
                    </div>

                    <CatFullCard catCard={objectArray[showObjectWindow] as CatCard} />
                  
                    <div className="full_card_column_button">
                        <div className="full_card_button">
                            <button 
                                className="full_card_close_button"
                                onClick={() => handlerCloseFullPetCardWindow()}>
                        
                                <img src="close_button.png" alt="Left"/>
                        
                            </button>
                        </div>
                        <div className="full_card_button">
                            <button                             
                                className={`full_card_step_button ${lockNextRecordButton ? 'disabled' : ''}`} 
                                disabled={lockNextRecordButton}
                                onClick={handlerNextRecord}>
                            
                                <img src="button_right.png" alt="Right"/>
                    
                            </button>
                        </div>
                        <div className="full_card_button"></div>
                    </div>
                </div>
            </div>
        </>

    const nurseryWindowJSX: JSX.Element =
        <>
            <div className="nursery_window_overlay">
                <div className="nursery_window_content_common">
                    <button className="full_card_close_button"
                        onClick={() => setShowObjectWindow(-1)}
                    >
                        <img src="close_button.png" alt="Left"/>
                    </button>
                    
                    <CatNurseryFullCard nurseryCard={objectArray[showObjectWindow] as NurseryCard} />

                </div>
            </div>
        </>

    const objectListJSX: JSX.Element[] = objectArray.map((item: CatCard | CatPhoto | NurseryCard, index: number) =>
        <div key={index} onClick={(event) => handlerClick(index, event)}>
            {(props.dataType === "CatCard") && <PetShortCard petCard={item as CatCard} isFavorite={true} />}
            {(props.dataType === "CatPhoto") && <CatPhotoCard photo={item as CatPhoto} />}
            {(props.dataType === "CatNursery") && <CatNurseryShortCard nurseryCard={item as NurseryCard} />}
        </div>
    );

    const objectFullCardJSX: JSX.Element = 
        <>
            {(props.dataType === "CatNursery") && nurseryWindowJSX }
            {(props.dataType === "CatCard") && showFullCatCardWindowJSX }
        </>

    useEffect(() => {
        requestObjectList(props.url);
      }, []);
    
    const loaderJSX : JSX.Element =
        <>
            <div className="loader_overlay">
                <span className="loader_orange"></span>
            </div>
        </>

    const errorJSX: JSX.Element =
        <>
            <div className="error_overlay">
                <div className="error_box">
                    <div className="error_text">
                        {error}
                    </div>
                    <button className="full_card_close_button"
                        onClick={() => handlerCloseErrorWindow()}
                    >
                            <img src="close_button.png" alt="Left"/>
                    </button>
                </div>

            </div>
        </>

    return (
        <>
            {loading && loaderJSX}
            {error && errorJSX}
            {(showObjectWindow >= 0) ? objectFullCardJSX : objectListJSX}
            { (paginationLastPage > 1) && paginationBlock }
        </>
    );
}

//{(!showObjectWindow === null) ? objectFullCardJSX : objectListJSX}
import { NurseryCard } from "./NurseryPage";
import { Parameter } from "./PetFullCardText";
import TelephoneNumberHiden from "./TelephoneNumberHiden";

interface CatNurseryCardProps {
    nurseryCard: NurseryCard;
}

export default function CatNurseryShortCard(props: CatNurseryCardProps): JSX.Element {
    const data: Parameter[] = [
        { name: 'Питомник: ',                   value: props.nurseryCard.name },
        { name: 'Заводчик: ',                   value: props.nurseryCard.breeder_name },
        { name: 'Клуб: ',                       value: props.nurseryCard.club },
        { name: 'Фелинологическая система: ',   value: props.nurseryCard.federation },
        { name: 'Город: ',                      value: props.nurseryCard.location},
        { name: 'Телефон: ',                    value: <TelephoneNumberHiden telNum={props.nurseryCard.phone_number || ''} telNumHiden={"+Х-ХXX-XXX-XX-XX"} className={"short_card_telephone_number"} />},
    ]

    const tableText = data.map((item, index) => (
        (item.value !== '') ?
            <tr className="row" key={index}>
                <td className="cell1">{item.name}</td>
                <td className="cell2">{item.value}</td>
            </tr> : <></>
    ));

    return (
        <>
        <div className="nursery">
            <table className="styled-table">
                <tbody>
                    {tableText}
                </tbody>
            </table>
        </div>
        </>
    );
}

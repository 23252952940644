import { NurseryCard } from "./NurseryPage";

interface CatNurseryCardProps {
    nurseryCard: NurseryCard;
}

export default function CatNurseryShortCard(props: CatNurseryCardProps): JSX.Element {
    const nurseryShortCard: JSX.Element = 
        <>
            <div className="nursery">
                <table className="styled-table">
                    <tbody>
                        <tr className="row">
                            <td className="cell1">{'Питомник: '}</td>
                            <td className="cell2">{props.nurseryCard.name}</td>
                        </tr>
                        <tr className="row">
                            <td className="cell1">{'Заводчик: '}</td>
                            <td className="cell2">{props.nurseryCard.breeder_name}</td>
                        </tr>
                        <tr className="row">
                            <td className="cell1">{'Клуб: '}</td>
                            <td className="cell2">{props.nurseryCard.club}</td>
                        </tr>
                        <tr className="row">
                            <td className="cell1">{'Фелинологическая система: '}</td>
                            <td className="cell2">{props.nurseryCard.federation}</td>
                        </tr>
                        <tr className="row">
                            <td className="cell1">{'Город: '}</td>
                            <td className="cell2">{props.nurseryCard.location}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </>


    return (
        <>
            {nurseryShortCard}
        </>
    );
}

import { useState } from "react";
import { CatCard, CatPhoto } from "./HomePage";
import PetFullCardText from "./PetFullCardText";
import TelephoneNumberHiden from "./TelephoneNumberHiden";

interface CatFullCardProps {
    catCard: CatCard;
}

export default function CatFullCard(props: CatFullCardProps): JSX.Element {
    const [activePhoto, setActivePhoto] = useState<number>(0);
    
    const handlerPrevPhoto = () => {
        if (activePhoto > 0) {
            setActivePhoto(activePhoto - 1);
        }
    }

    const handlerNextPhoto = () => {
        if (activePhoto < props.catCard.photos.length - 1) {
            setActivePhoto(activePhoto + 1);
        }
    }

    const sliderButtonsJSX = props.catCard.photos.map((item: CatPhoto, index: number) =>
        <>
            <div key={index} 
                className={(activePhoto===index) ? "photo_slider_item_active" : "photo_slider_item"} 
                onClick={() => setActivePhoto(index)}
            >
            </div>
        </>
    );

    return (
        <>
            <div className="full_card">
                <div className="full_card_image">
                    <img alt={"photo"} 
                        src={(props.catCard.photos.length > 0) ? props.catCard.photos[activePhoto].path_file : ""} 
                            className="full_photo"
                        />
            
                        {
                            (props.catCard.photos.length>1) ? 
                                <div className="full_card_button_bar">
                                    <button className="photo_slider_button" 
                                        onClick={() => handlerPrevPhoto()}
                                    >
                                        {(props.catCard.photos.length > 2 && activePhoto > 0) ? "<" : " "}
                                    </button>

                                    {sliderButtonsJSX}
                        
                                    <button className="photo_slider_button" onClick={() => handlerNextPhoto()}>
                                        {(props.catCard.photos.length > 2) && (activePhoto < props.catCard.photos.length - 1) ? ">" : " "}
                                    </button>
                                </div> : <></>
                        }
                    </div>
                    <div className="full_card_text">
                        <PetFullCardText petCard={props.catCard} />

                        <TelephoneNumberHiden telNum={props.catCard.tel_number || ''} 
                            telNumHiden={"+Х-ХXX-XXX-XX-XX"} 
                            className={"full_card_telephone_number"} 
                        />
                    </div>
                </div>
        </>
    );
}